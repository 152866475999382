import axios from "axios";
import { isBrowser } from "browser-or-node";
import { transformRequestOptions, getCompany } from "./../../helper/utils";
import { LocalStorageService } from "./../localstorage.service";
axios.defaults.withCredentials = true;

/**
 * set axios config for SSR
 * @param {*} config
 */

const getHeaders = (headers, hasContent = true) => {
    return {
        ...(hasContent ? { "Content-Type": "application/json" } : {}),
        "x-company-id": getCompany(),
        ...headers
    };
};

let engine = {};

engine.head = function (url, opt) {
    return axios.head(url, {
        headers: getHeaders(opt.headers, false),
        params: opt.params,
        paramsSerializer: params => {
            return transformRequestOptions(params);
        }
    });
};

engine.get = function (url, opt) {
    return axios.get(url, {
        params: opt.params,
        headers: getHeaders(opt.headers, false),
        paramsSerializer: params => {
            return transformRequestOptions(params);
        }
    });
};

engine.post = function (url, opt) {
    return axios.post(url, opt.data, {
        headers: getHeaders(opt.headers),
        params: opt.params
    });
};

engine.put = function (url, opt) {
    return axios.put(url, opt.data, {
        headers: getHeaders(opt.headers),
        params: opt.params
    });
};

engine.patch = function (url, opt) {
    return axios.patch(url, opt.data, {
        headers: getHeaders(opt.headers),
        params: opt.params
    });
};

engine.del = function (url, opt) {
    return axios.delete(url, {
        data: opt.data,
        headers: getHeaders(opt.headers, false),
        params: opt.params
    });
};

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (401 === (error.response && error.response.status)) {
            if (isBrowser) {
                LocalStorageService.removeAll();
            }
        }
        return Promise.reject(error);
    }
);

export default engine;
