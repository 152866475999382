<template>
  <div class="loader">
    <img src="../../assets/loader.gif" alt="" />
    <div class="help-text" v-if="helperText">{{ helperText }}</div>
    <nitrozen-button
      v-if="action"
      theme="secondary"
      title="Cancel"
      @click="$emit('cancel')"
    >
      Cancel
    </nitrozen-button>
  </div>
</template>
<script>
import { NitrozenButton } from "@gofynd/nitrozen-vue";

export default {
  name: "adm-loader",
  components: {
    NitrozenButton,
  },
  props: {
    helperText: {
      type: String,
      default: "",
    },
    action: {
      default: () => {
        return false;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.load-backdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.3;
}

.loader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  background: hsla(0, 0%, 83%, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100px;
    width: 100px;
  }
  .help-text {
    font-weight: bold;
  }
}
</style>
