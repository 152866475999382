import urlJoin from "url-join";
import root from "window-or-global";
let envVars = root.env || {};

envVars.MYNTRA_MAIN_DOMAIN = `${root.location.protocol}//${root.location.hostname}`;
if (
    root &&
    root.process &&
    root.process.env &&
    root.process.NODE_ENV == "test"
) {
    envVars = root.process.env;
}

let Endpoints = {
    MARKETPLACE_CONFIG: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/company/config'
        );
    },
    LOCATION_STATS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/location/stats'
        );
    },
    LOCATION_CANCEL_JOBS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/location/config/exportjob/cancel'
        );
    },
    RESOURCE_SYNC: () => {
        return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, "api/v1/sync");
    },

    RESOURCE_SYNC_LAST_JOB: () => {
        return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, "api/v1/sync/%s/status");
    },
    PRODUCTS_CANCEL_JOBS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/products/exportjob/cancel'
        );
    },
    LOCATION_LAST_JOB: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/location/last-job'
        );
    },
    PRODUCT_LAST_JOB: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/products/last-job'
        );
    },
    MARKETPLACE_STORES: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/location/config'
        );
    },
    IS_COMPANY_NEW: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN, 
            'api/v1/company/onboard/status'
        );
    },
    DOWNLOAD_STORE_MAPPING: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/location/config/generate'
        );
    },
    DOWNLOAD_STORE_JOB_STATUS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/location/generate/status'
        );
    },
    BULK_UPLOAD_STORE_MAPPING: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/location/config/bulk'
        );
    },
    BULK_UPLOAD_STORE_JOB_STATUS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/company/location/config/bulk/status'
        );
    },
    MARKETPLACE_SYNC_CONFIG: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/company/inventory/config'
        );
    },
    MARKETPLACE_STATUS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/company/status'
        );
    },
    MARKETPLACE_IDENTIFIERS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/identifiers'
        );
    },
    ARTICLE_IDENTIFIERS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/article-identifiers'
        );
    },
    PRODUCT_STATS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/products/stats'
        );
    },
    BRANDS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/products/brands'
        );
    },
    MARKETPLACES_ID_MAP: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/products'
        );
    },
    MARKETPLACES_ID_MAP_BULK: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/products/bulk'
        );
    },
    DOWNLOAD_MAPPING: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/products/generate/map'
        );
    },
    DOWNLOAD_JOB_STATUS: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/id-map/generate/status'
        );
    },
    INVENTORY_UPDATES: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/inv-sync'
        );
    },
    TRACE_INVENTORY: () => {
        return urlJoin(
            envVars.MYNTRA_MAIN_DOMAIN,
            'api/v1/inv-sync/logs/product'
        );
    },
    MARKETPLACE_LOGS: () => {
        return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, `api/v1/inv-sync/logs/`);
    },
    // SEARCH_MARKETPLACE_LOGS: () => {
    //     return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, `/v1/company/${getCompInfo()}/logs/marketplaces/search`);
    // },
    EXPORT_CSV: () => {
        return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, `api/v1/inv-sync/logs`);
    },
    EXPORT_JOB_STATUS: (jobId) => {
        return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, `api/v1/inv-sync/logs/job/${jobId}/status`);
    },
    SUBSCRIPTION(plan_id = '') {
        console.log("hi")
        return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, `/api/v1/subscription/${plan_id}`)
    },
    SUBSCRIPTION_STATUS(subscription_id) {
        return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, `/api/v1/subscription/${subscription_id}/status/`)
    },
    FP_SYNC(){
        return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, `api/v1/company/sync/all`)
    },
    PLANS() {
        return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, `/api/v1/plans`)
    },
    VERTICALS() {
        return urlJoin(envVars.MYNTRA_MAIN_DOMAIN, `/tenants`)
    },
};

export default Endpoints;