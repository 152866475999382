
import { isBrowser } from 'browser-or-node';
import AES from "crypto-js/aes"
import Utf8 from "crypto-js/enc-utf8"

let company_id = null;

export const debounce = (func, wait, immediate) => {
    var timeout;

    // This is the function that is actually executed when
    // the DOM event is triggered.
    return function executedFunction() {
        // Store the context of this and any
        // parameters passed to executedFunction
        var context = this;
        var args = arguments;

        // The function to be called after
        // the debounce time has elapsed
        var later = function () {
            // null timeout to indicate the debounce ended
            timeout = null;

            // Call function now if you did not on the leading end
            if (!immediate) func.apply(context, args);
        };

        // Determine if you should call the function
        // on the leading or trail end
        var callNow = immediate && !timeout;

        // This will reset the waiting every function execution.
        // This is the step that prevents the function from
        // being executed because it will never reach the
        // inside of the previous setTimeout
        clearTimeout(timeout);

        // Restart the debounce waiting period.
        // setTimeout returns a truthy value (it differs in web vs node)
        timeout = setTimeout(later, wait);

        // Call immediately if you're dong a leading
        // end execution
        if (callNow) func.apply(context, args);
    };
};

export const detectFPApp = () => {
    if (isBrowser) {
        return window.__fpAppDetails;
    }
    return false;
};


export const transformRequestOptions = params => {
    let options = '';

    for (const key in params) {
        if (typeof params[key] !== 'object' && params[key]) {
            const encodeVal = encodeURIComponent(params[key]);
            options += `${key}=${encodeVal}&`;
        } else if (Array.isArray(params[key])) {
            params[key].forEach(el => {
                const encodeVal = encodeURIComponent(el);
                options += `${key}=${encodeVal}&`;
            });
        } else if (typeof params[key] === 'object' && params[key]) {
            options += transformRequestOptions(params[key]);
        }
    }
    return options ? options.slice(0, -1) : options;
};

export const titleCase = str => {
    str = str.toLowerCase().split('_');
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
};


export const toggleString = (status, style) => {
    let str = status ? 'enabled' : 'disabled';
    if (style === 'titleCase') {
        str = titleCase(str);
    } else if (style === 'upperCase') {
        str = str.toUpperCase(str);
    }

    return str;
};



export const getQuerystring = (key) => {
    const queryStr = window.location.search.substring(1);
    const queries = queryStr.split("&");
    for (let query of queries) {
        const pair = query.split("=");
        if (pair[0] == key) {
            return pair[1];
        }
    }
}

export const setCompany = (companyId) => {
    company_id = companyId;
}

export const getCompany = () => {
    return company_id;
}

export const getCompanyBasePath = route => {
    return `/company/${route && route.params && route.params.company_id || getCompany()}`;
};

export const detectMobileWidth = () => {
    if (isBrowser) {
        return window && window.screen.width <= 768;
    }
};

export const detectMobile = () => {
    return (
        navigator &&
        (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i))
    );
};

export const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = AES.decrypt(ciphertext, passphrase);
    return bytes.toString(Utf8);
}
